import { Component, Inject, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { fromEvent } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { IVisitProductConfig, IVisitProductData } from '../visit-product-overview/visit-product-overview.declarations';
import { TranslatePipe } from '../translate.pipe';

@Component({
  selector: 'app-root',
  templateUrl: './krpano.component.html',
  styleUrls: ['./krpano.component.scss']
})
export class KrpanoComponent implements OnInit {
  public krpanoUrl: SafeResourceUrl;
  public videoIntroUrl: SafeResourceUrl;
  public skipIntroText: String = "";
  public showVideo: Boolean = true;
  public destroyVideo: Boolean = false;
  constructor(public dialog: MatDialog, private http: HttpClient, public sanitizer: DomSanitizer) {
  }

  ngOnInit(): void {
    let url = "";
    let videoUrl = "";

    if(TranslatePipe.CurrentLang === "fr_FR"){
      url = "/assets/visit/engine/index_fr.html";
      videoUrl = "assets/visit/video/intro.mp4";
      this.skipIntroText = "PASSER L'INTRO";
    }
    else if(TranslatePipe.CurrentLang === "ja_JP"){
      url = "/assets/visit/engine/index_jp.html";
      videoUrl = "assets/visit/video/intro.mp4";
      this.skipIntroText = "スキップ導入";
    }
    else if(TranslatePipe.CurrentLang === "zh_TW"){
      url = "/assets/visit/engine/index_tw.html";
      videoUrl = "assets/visit/video/intro.mp4";
      this.skipIntroText = "跳過介紹";
    }
    else if(TranslatePipe.CurrentLang === "zh_CN"){
      url = "/assets/visit/engine/index_chs.html";
      videoUrl = "assets/visit/video/intro.mp4";
      this.skipIntroText = "跳过介绍";
    }
    else if(TranslatePipe.CurrentLang === "ru_RU"){
      url = "/assets/visit/engine/index_ru.html";
      videoUrl = "assets/visit/video/intro.mp4";
      this.skipIntroText = "пропустить введение";
    }
    else {
      url = "/assets/visit/engine/index.html";
      videoUrl = "assets/visit/video/intro.mp4";
      this.skipIntroText = "SKIP INTRO";
    }

    this.krpanoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    this.videoIntroUrl = this.sanitizer.bypassSecurityTrustResourceUrl(videoUrl);
    fromEvent(window, 'message')
      .subscribe((message: MessageEvent<IKrpanoEvent>) => {
        if (!message.data?.type || !message.data?.data) {
          return;
        }

        switch (message.data.type) {
          case 'krpanoHotSpotClick':
            this.loadRef(message.data.data);
            break;
        }

      });
  }

  closeVideo(){
    this.showVideo = false;
  }

  transitionEnd(e: Event){
    this.destroyVideo = true;
  }

  private async loadRef(ref: string): Promise<void> {
    let data: IVisitProductData | null = null;

    try {
      data = await this.http.get<IVisitProductData>(`/assets/visit/data/${ref}/data.json`).toPromise();
    } catch (e) {
      console.warn(e);
      alert('Error load data');

      return;
    }

    if (!data) {
      alert('Data is null');

      return;
    }

    this.dialog.open(DialogKrPano, {
      data: {
        data,
        ref,
      }
    });
  }
}

export interface IKrpanoEvent<T = any> {
  type: string;
  data?: T;
}

@Component({
  selector: 'dialog-krpano',
  templateUrl: 'dialog-krpano.html',
})
export class DialogKrPano {
  constructor(@Inject(MAT_DIALOG_DATA) public data: IVisitProductConfig, private dialogRef: MatDialogRef<DialogKrPano>) {}

  submitClose(): void {
    this.dialogRef.close();
  }
}
